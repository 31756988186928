<template>
  <div class="category__section" :style="style">
    <div v-if="chart.isDefault && chart.categoryId === 'nivellaboral'" class="section__cards">
      <Card title="Empleados" icon="user" :value="employees.length" :colors="colors" />
      <Card
        title="Masculinos"
        icon="mars"
        :value="employees.filter((employee) => employee.gender === 'Masculino').length"
        :total="employees.length"
        :colors="colors"
      />
      <Card
        title="Femeninos"
        icon="venus"
        :value="employees.filter((employee) => employee.gender === 'Femenino').length"
        :total="employees.length"
        :colors="colors"
      />
    </div>

    <div v-else-if="chart.isDefault && showCategoryGender" class="section__cards">
      <Card
        title="Neutros"
        subTitle="Puestos"
        icon="suitcase"
        :value="
          chartData.datasets[0].data.filter((data) => data < company.genderRatio).length -
          chartData.datasets[1].data.filter((data) => data >= company.genderRatio).length
        "
        :total="chartData.datasets[0].data.length"
        :colors="colors"
      />
      <Card
        title="Masculinos"
        subTitle="Puestos"
        icon="suitcase"
        :value="chartData.datasets[0].data.filter((data) => data >= company.genderRatio).length"
        :total="chartData.datasets[0].data.length"
        :colors="colors"
      />
      <Card
        title="Femeninos"
        subTitle="Puestos"
        icon="suitcase"
        :value="chartData.datasets[1].data.filter((data) => data >= company.genderRatio).length"
        :total="chartData.datasets[1].data.length"
        :colors="colors"
      />
    </div>

    <div
      class="section__chart"
      :class="{
        category__haveCard:
          chart.isDefault && (chart.categoryId === 'nivellaboral' || chart.categoryId === 'puesto'),
      }"
    >
      <div class="chart__title">
        <h3>{{ displayName }}</h3>

        <Menu direction="left">
          <template #label>
            <unicon
              class="ellipsis"
              name="ellipsis-h"
              fill="currentColor"
              height="16.5px"
              width="16.5px"
            />
          </template>
          <template #options>
            <menu-item v-if="isTableView" @click="isTableView = false">
              <unicon width="16px" height="16px" name="chart" fill="var(--font-color-700)"></unicon>
              Ver gráfico
            </menu-item>
            <menu-item v-else @click="isTableView = true">
              <unicon width="16px" height="16px" name="table" fill="var(--font-color-700)"></unicon>
              Ver tabla
            </menu-item>
            <menu-item @click="downloadExcel">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar Excel
            </menu-item>
            <span v-if="!chart.isDefault" class="menu__divider"></span>
            <menu-item v-if="!chart.isDefault" @click="$emit('delete', chart)" :isDanger="true">
              <unicon
                width="16px"
                height="16px"
                name="trash"
                fill="var(--danger-color-400)"
              ></unicon>
              Eliminar
            </menu-item>
          </template>
        </Menu>
      </div>
      <div v-if="!isTableView" class="chart__container">
        <bar-chart
          :chart-data="chartData"
          :chart-options="{
            max: 1,
            steps: 5,
            stepSize: 0.2,
            indexAxis: chart.direction === 'v' ? 'x' : 'y',
            scales: {
              [chart.direction === 'v' ? 'y' : 'x']: {
                stacked: chart.stacked,
                display: !chart.stacked,
              },
              [chart.direction === 'v' ? 'x' : 'y']: {
                stacked: chart.stacked,
                position: 'right',
              },
            },
            plugins: {
              tooltip: {
                xAlign: chart.direction === 'v' ? 'center' : 'right',
                yAlign: chart.direction === 'v' ? 'bottom' : 'center',
                callbacks: {
                  title: ([context]) => chartData.labels[context.dataIndex].join(' '),
                  label: (context) => {
                    return [
                      'Valor: ' +
                        Math.round(
                          context.parsed[chart.direction === 'v' ? 'y' : 'x'] *
                            chartData.dataTotals[context.dataIndex],
                        ),
                    ];
                  },
                },
              },
            },
          }"
        />
      </div>
      <div v-show="isTableView" class="table__container">
        <table ref="table">
          <tbody>
            <tr>
              <th rowspan="2">{{ categoryName }}</th>
              <th v-if="chart.categoryId === 'puesto' && chart.isDefault" rowspan="2">
                Género <br />
                del puesto
              </th>
              <th colspan="2">Masculino</th>
              <th colspan="2">Femenino</th>
              <th rowspan="2">Total</th>
            </tr>
            <tr class="second__header">
              <th>#</th>
              <th>%</th>
              <th>#</th>
              <th>%</th>
            </tr>
            <tr v-for="index in chartData.labels.length" :key="`${index}-categoryTable`">
              <td>{{ chartData.labels[index - 1][0] }}</td>
              <td v-if="chart.categoryId === 'puesto' && chart.isDefault">
                {{ chartData.labels[index - 1][1].replace(/\(|\)/g, '') }}
              </td>
              <template v-for="genderIndex in 2">
                <td :key="`${genderIndex}-${index}-value`">
                  {{
                    Math.round(
                      chartData.datasets[genderIndex - 1].data[index - 1] *
                        chartData.dataTotals[index - 1],
                    )
                  }}
                </td>
                <td :key="`${genderIndex}-${index}-percent`">
                  {{
                    (chartData.datasets[genderIndex - 1].data[index - 1] * 100)
                      .toFixed(0)
                      .replace(/[.,]0$/, '')
                  }}
                </td>
              </template>
              <td>{{ chartData.dataTotals[index - 1] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import BarChart from '@/components/charts/BarChart.vue';
import Card from '@/components/Card.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';

export default {
  components: {
    BarChart,
    Card,
    Menu,
    MenuItem,
  },
  data() {
    return { isTableView: false };
  },

  props: {
    employees: {
      required: true,
      type: Array,
    },
    chart: {
      required: true,
      type: Object,
    },
    colors: {
      type: Array,
      required: true,
    },
    showCategoryGender: {
      default: false,
    },
  },

  methods: {
    getPositionGender(val) {
      if (val >= this.company.genderRatio) return 'Masculino';
      if (1 - val >= this.company.genderRatio) return 'Femenino';
      return 'Neutro';
    },

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    downloadExcel() {
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);
      const wb = {
        SheetNames: [this.displayName],
        Sheets: { [this.displayName]: sheet },
      };

      wb.SheetNames.forEach((sheetName) => {
        const ws = wb.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(wb, {
        bookType: 'xlsx',
        type: 'binary',
      });
      this.saveBlobAsFile(
        'reporte.xlsx',
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, genderRatio: state.company.genderRatio || 0.6 }),
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    displayName() {
      return `${this.categoryName} x Género`;
    },

    style() {
      if (this.chart.direction === 'v') {
        const maxLength = this.chart.stacked ? 12 : 7;
        const amount = this.chart.stacked ? 3 : 6;
        return {
          '--chart-height': '100%',
          '--chart-width':
            this.chartData.dataTotals.length < maxLength
              ? '100%'
              : `${this.chartData.dataTotals.length * amount}rem`,
        };
      }
      return {
        '--chart-height':
          this.chartData.dataTotals.length < 14
            ? '100%'
            : `${this.chartData.dataTotals.length * 2}rem`,
        '--chart-width': '100%',
      };
    },
    categoryName() {
      const cat = this.categories.find((category) => category.id === this.chart.categoryId);
      return cat ? cat.name : '';
    },
    chartData() {
      const { categoryId } = this.chart;
      const categoryOptions = this.options
        .filter((option) => option.categoryId === categoryId)
        .map((option, index) => ({ ...option, index }));
      let datasets = [
        {
          label: 'Masculino',
          backgroundColor: this.colors[0],
          data: categoryOptions.map(() => 0),
        },
        {
          label: 'Femenino',
          backgroundColor: this.colors[1],
          data: categoryOptions.map(() => 0),
        },
      ];

      const dataTotals = categoryOptions.map(() => 0);

      this.employees.forEach((employee) => {
        const employeeGenderIndex = employee.gender === 'Masculino' ? 0 : 1;
        const option = categoryOptions.find((optn) => optn.id === employee[categoryId]);
        if (option) {
          datasets[employeeGenderIndex].data[option.index] += 1;
          dataTotals[option.index] += 1;
        }
      });
      /* eslint-disable */
      const indexesOf = (arr) => arr.reduce((acc, v, i) => (v === 0 && acc.push(i), acc), []);
      const emptyIndexes = indexesOf(datasets[0].data).filter((el) =>
        indexesOf(datasets[1].data).includes(el),
      );

      datasets = datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data
          .map((data, index) => data / dataTotals[index] || 0)
          .filter((_, index) => emptyIndexes.indexOf(index) === -1),
      }));

      const labels = categoryOptions
        .map((option) => option.name)
        .filter((_, index) => emptyIndexes.indexOf(index) === -1)
        .map((name, index) => {
          return this.showCategoryGender
            ? [name, `(${this.getPositionGender(datasets[0].data[index])})`]
            : [name];
        });

      return {
        labels,
        datasets,
        dataTotals: dataTotals.filter((_, index) => emptyIndexes.indexOf(index) === -1),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.category__section {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  height: 100%;
}

.section__cards {
  display: flex;
  gap: 1rem;
}

.section__chart {
  border: solid 1px var(--gray-color-500);
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  .chart__title {
    display: flex;
    justify-content: space-between;
  }
  &.category__haveCard {
    height: calc(100% - 7rem);
  }
  height: 100%;
  h3 {
    padding-bottom: 1rem;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .chart__container {
    // flex-grow: 1;
    height: 100%;
    padding: 0 1rem;
    overflow-y: auto;
    & > div {
      height: var(--chart-height);
      width: var(--chart-width);
    }
  }

  .table__container {
    margin-top: 0.3rem;
    overflow: auto;
    table {
      min-width: 100%;
    }
  }
}

.section__cards {
  display: flex;
  gap: 1rem;
}
</style>
