<template>
  <div>
    <Modal size="sm" ref="modal__genderRatio">
      <template #title>Género de puesto</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(editGenderRatio)">
            <div class="genderRatio__modal">
              <p>
                Para determinar el género de un puesto la OIT recomienda que las personas que ocupen
                una posición sea superior al 60% en su totalidad; sin embargo, la plataforma te
                permite configurar el porcentaje que se adecue a la organización.
              </p>

              <div>
                <p>Género de puesto:</p>
                <span>
                  <p>Porcentaje de empleados de género predominante</p>
                  <h4>>=</h4>
                  <UnitInput
                    unit="%"
                    :rules="{
                      required: true,
                      between: { min: 51, max: 100 },
                    }"
                    :value="ratio"
                    @input="ratio = $event"
                  />
                </span>
              </div>
            </div>

            <div class="modal__buttons">
              <Button @click="close" size="medium" variant="text">Cancelar</Button>
              <Button type="submit" :disabled="invalid" size="medium" variant="primary">
                Editar</Button
              >
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/Modal.vue';
import UnitInput from '@/components/custom/UnitInput.vue';
import Button from '@/components/buttons/Button.vue';

export default {
  name: 'GenderRatioModal',
  components: {
    Modal,
    UnitInput,
    ValidationObserver,
    Button,
  },
  data() {
    return {
      ratio: 0,
    };
  },

  props: {
    employees: {
      type: Array,
    },
  },

  methods: {
    ...mapActions(['updateCompany']),
    ...mapMutations(['setAlert']),
    open() {
      this.$refs.modal__genderRatio.open();
    },

    close() {
      this.$refs.modal__genderRatio.close();
    },

    async editGenderRatio() {
      try {
        await this.updateCompany({
          ...this.company,
          genderRatio: parseFloat((this.ratio / 100).toFixed(2)),
        });
        this.setAlert({
          state: 'success',
          message: 'Empresa actualizada',
        });
      } catch (e) {
        console.log(e);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
      this.close();
    },
  },

  mounted() {
    this.ratio = parseInt((this.company.genderRatio * 100).toFixed(0), 10);
  },

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, genderRatio: state.company.genderRatio || 0.6 }),
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
  },
};
</script>

<style lang="scss" scoped>
.genderRatio__modal {
  padding: 1.5rem 1.5rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  p {
    line-height: 1rem;
    color: var(--font-color-900);
  }

  & > p:first-child {
    margin-bottom: 1rem;
  }

  & > div {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    & > p {
      font-weight: var(--semi-bold);
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

form {
  & > div {
    display: flex;
  }
}

.modal__buttons {
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
</style>
