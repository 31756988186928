<template>
  <ValidationProvider
    class="unit-input"
    tag="label"
    v-slot="{ errors }"
    :mode="mode"
    :name="name"
    :rules="rules"
    :vid="vid"
  >
    <input
      class="unit-input__field"
      type="number"
      :class="{ invalid: errors.length }"
      :disabled="disabled"
      :id="id"
      :placeholder="placeholder"
      :value="value"
      @input="inputHandler"
      @blur="$emit('blur', $event)"
    />
    <span class="unit-input__unit" :class="{ invalid: errors.length }">{{ unit }}</span>

    <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationProvider },
  props: {
    disabled: Boolean,
    id: String,
    mode: {
      default: 'aggressive',
      type: [Function, String],
    },
    name: String,
    placeholder: String,
    rules: [Object, String],
    unit: String,
    value: Number,
    vid: String,
  },

  methods: {
    inputHandler(event) {
      const number = parseInt(event.target.value, 10) || 0;
      this.$emit('input', number);
    },
  },
};
</script>

<style lang="scss" scoped>
.unit-input {
  position: relative;
  display: flex;
  align-items: stretch;

  &__field {
    max-width: calc(4ch + 0.5em * 2);
    padding: 0.375rem;
    color: #474646;
    font-weight: 500;
    border: 1px solid #e8e8e8;
    border: 1px solid  var(--gray-color-900);
    border-radius: 3.5px 0 0 3.5px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:disabled {
      color: var(--font-color-300);
      background-color: var(--gray-color-200);
      cursor: not-allowed;
    }
  }

  &__unit {
    padding: 0.375rem;
    color: #928e8e;
    font-weight: 500;
    background-color: #eaebee;
    border: 1px solid  var(--gray-color-900);
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;
  }

  & *.invalid {
    border-color: var(--danger-color-500);
  }
}

.error-message {
  position: absolute;
  top: calc(100% + 0.5em);
  right: 0;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  padding: 0.375rem;
  color: var(--danger-color-600);
  font-size: 0.75rem;
  background-color: var(--danger-color-100);
  border-radius: 4px;
  z-index: var(--index-1);
  transition: opacity 300ms;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 40px;
    width: 0;
    border-bottom: 5px solid var(--danger-color-100);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .unit-input:hover & {
    visibility: visible;
    opacity: 1;
  }
}
</style>
