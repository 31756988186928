var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category__section"},[_c('div',{staticClass:"section__chart"},[_c('div',{staticClass:"chart__title"},[_c('h3',[_vm._v(_vm._s(_vm.displayName))]),_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16.5px","width":"16.5px"}})]},proxy:true},{key:"options",fn:function(){return [(_vm.isTableView)?_c('menu-item',{on:{"click":function($event){_vm.isTableView = false}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"chart","fill":"var(--font-color-700)"}}),_vm._v(" Ver gráfico ")],1):_c('menu-item',{on:{"click":function($event){_vm.isTableView = true}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"table","fill":"var(--font-color-700)"}}),_vm._v(" Ver tabla ")],1),_c('menu-item',{on:{"click":_vm.downloadExcel}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar Excel ")],1)]},proxy:true}])})],1),(!_vm.isTableView)?_c('div',{staticClass:"chart__container"},[_c('doughnut-chart',{attrs:{"chart-data":_vm.chartData,"chart-options":{
          cutout: 0,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return [
                    ("Valor: " + (Math.round(
                      _vm.chartData.datasets[0].data[context.dataIndex] * _vm.employees.length
                    ))) ];
                },
                title: function (ref) {
                        var context = ref[0];

                        return _vm.chartData.labels[context.dataIndex];
},
              },
            },
          },
        }}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTableView),expression:"isTableView"}],staticClass:"table__container"},[_c('table',{ref:"table"},[_c('tbody',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.categoryName))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Masculino")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Femenino")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Total")])]),_vm._m(0),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.categoryName))]),_c('td',[_vm._v(" "+_vm._s(Math.round(_vm.chartData.datasets[0].data[0] * _vm.chartData.total))+" ")]),_c('td',[_vm._v(" "+_vm._s((_vm.chartData.datasets[0].data[0] * 100).toFixed(1).replace(/[.,]0$/, ''))+" ")]),_c('td',[_vm._v(" "+_vm._s(Math.round(_vm.chartData.datasets[0].data[1] * _vm.chartData.total))+" ")]),_c('td',[_vm._v(" "+_vm._s((_vm.chartData.datasets[0].data[1] * 100).toFixed(1).replace(/[.,]0$/, ''))+" ")]),_c('td',[_vm._v(_vm._s(_vm.chartData.total))])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"second__header"},[_c('th',[_vm._v("#")]),_c('th',[_vm._v("%")]),_c('th',[_vm._v("#")]),_c('th',[_vm._v("%")])])}]

export { render, staticRenderFns }