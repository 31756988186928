<template>
  <div>
    <Modal size="sm" ref="modal__chart">
      <template #title>Nuevo Gráfico</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="chart__modal">
              <span>
                <custom-select
                  v-model="chart.categoryId"
                  :options="
                    categories.map((category) => ({ id: category.id, name: category.name }))
                  "
                  label="Categoría"
                  rules="required"
                />

                <div class="chart__buttons--radio">
                  <ValidationProvider tag="label" class="radio__label">
                    <input
                      type="radio"
                      checked="checked"
                      name="radio"
                      value="v"
                      v-model="chart.direction"
                      @input="
                        chart.type = 'Barras';
                        vIf = 1;
                      "
                    />
                    <span class="checkmark">
                      <unicon name="chart-bar" fill="currentColor" height="25px" width="25px" />
                      <p>Barras</p>
                    </span>
                  </ValidationProvider>
                  <ValidationProvider tag="label" class="radio__label">
                    <input
                      type="radio"
                      name="radio"
                      value="h"
                      v-model="chart.direction"
                      @input="
                        chart.type = 'Barras';
                        vIf = 1;
                      "
                    />
                    <span class="checkmark">
                      <unicon name="chart-bar-alt" fill="currentColor" height="25px" width="25px" />
                      <p>Barras H</p>
                    </span>
                  </ValidationProvider>
                  <ValidationProvider tag="label" rules="required" class="radio__label">
                    <input
                      type="radio"
                      name="radio"
                      value="Dónut"
                      v-model="chart.type"
                      @input="
                        chart.direction = '';
                        vIf = 2;
                      "
                    />
                    <span class="checkmark">
                      <unicon name="chart-pie" fill="currentColor" height="25px" width="25px" />
                      <p>Dónut</p>
                    </span>
                  </ValidationProvider>
                  <ValidationProvider tag="label" rules="required" class="radio__label">
                    <input
                      type="radio"
                      name="radio"
                      value="Lineal"
                      v-model="chart.type"
                      @input="
                        chart.direction = '';
                        vIf = 0;
                      "
                    />
                    <span class="checkmark">
                      <unicon name="chart-line" fill="currentColor" height="25px" width="25px" />
                      <p>Lineal</p>
                    </span>
                  </ValidationProvider>
                </div>

                <checkbox
                  v-if="vIf === 1"
                  v-model="chart.stacked"
                  label="Barras apiladas"
                  rules="required"
                />

                <custom-select
                  v-else-if="vIf === 2"
                  v-model="chart.gender"
                  :options="[
                    { id: 'Masculino', name: 'Masculino' },
                    { id: 'Femenino', name: 'Femenino' },
                  ]"
                  label="Género"
                  rules="required"
                />
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <Button type="submit" :disabled="invalid || isLoading" variant="primary" size="small">
                {{ !isLoading ? 'Crear gráfico' : 'Agregando...' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Button from '@/components/buttons/Button.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import Checkbox from '@/components/custom/Checkbox.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'CategoryModal',
  components: {
    Button,
    Modal,
    CustomSelect,
    ValidationObserver,
    ValidationProvider,
    Checkbox,
  },
  data() {
    return {
      chart: {},
      vIf: 0,
      isLoading: false,
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('categories', ['fetchCategories', 'addCategory']),
    ...mapActions('charts', ['fetchCharts', 'addChart']),

    async onAction() {
      this.isLoading = true;
      try {
        await this.addChart(this.chart);
        this.setAlert({
          state: 'success',
          message: 'Gráfico creado',
        });
      } catch (error) {
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      }
      this.chart = {};
      this.isLoading = false;
      this.close();
    },

    async open() {
      this.$refs.modal__chart.open();
    },

    close() {
      this.$refs.modal__chart.close();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
    }),
  },
};
</script>

<style lang="scss" scoped>
.form__validation {
  height: 100%;

  form {
    height: 100%;
  }
}

.chart__modal {
  padding: 1.8rem 1.8rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;

  & > span:first-child {
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
  }
}

.chart__buttons--radio {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.radio__label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 5.5rem;
  height: 4.5rem;
  color: var(--font-color-200);
}

.radio__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 2px var(--gray-color-200);
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  p {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: var(--light);
  }
}

/* On mouse-over, add a grey background color */
.radio__label:hover input:not(:checked) ~ .checkmark {
  background-color: var(--gray-color-100);
}

/* When the radio button is checked, add a blue background */
.radio__label input:checked ~ .checkmark {
  border-color: var(--main-color-500);
  * {
    color: var(--main-color-500);
    font-weight: vaR(--regular);
  }
}
</style>
