<template>
  <div v-click-outside="close" class="custom__side-menu" :class="{ isOpen }">
    <div @click="toggle"><slot name="label"></slot></div>

    <transition name="menu">
      <ul v-show="isOpen" v-if="!Object.keys(selectedItem).length" class="menu__menu">
        <span class="title">
          <p>
            {{ title }}
          </p>
        </span>
        <div class="input__container">
          <div class="input">
            <label>
              <input
                v-model="searchValue"
                class="field"
                type="search"
                placeholder="Buscar opción..."
                autocomplete="off"
              />
            </label>
          </div>
        </div>

        <div class="menu__items" :class="{ 'menu__items-noHandle': !isSortable }">
          <sortable-list
            v-model="itemsCopy"
            lockAxis="y"
            :useDragHandle="true"
            :lockToContainerEdges="true"
            :draggedSettlingDuration="0"
          >
            <sortable-item
              v-for="(item, index) in itemsCopy.filter(({ name }) =>
                name.toLowerCase().includes(searchValue.toLowerCase()),
              )"
              :key="item.id"
              :index="index"
              size="sm"
            >
              <!-- <span><p>{{ option.index + 1 }}.</p></span> -->
              <menu-item @click="selectedItem = item">
                <div class="menu__item">
                  <p>{{ item.name }}</p>
                  <div>
                    <unicon
                      v-if="toggleButton"
                      width="16px"
                      height="16px"
                      :name="
                        item[toggleButton.toggleProperty] ? toggleButton.icon : toggleButton.icon2
                      "
                      fill="var(--font-color-700)"
                    ></unicon>
                    <unicon width="18px" height="18px" name="angle-right" fill="#b1b0ae"></unicon>
                  </div>
                </div>
              </menu-item>
            </sortable-item>
          </sortable-list>

          <span class="menu__divider"></span>
          <Button
            @click="
              close();
              $emit(actionButton.action);
            "
            size="small"
            variant="secondary-text"
          >
            <unicon width="18px" height="18px" name="plus" fill="#B1B0AE"></unicon>
            {{ actionButton.label }}
          </Button>
        </div>
      </ul>
      <ul v-show="isOpen" v-else class="menu__menu">
        <span class="title">
          <icon-button label="categorías" icon="arrow-left" @click="selectedItem = {}" />
          <Hovertext :text="selectedItem.name">
            <p>
              {{ selectedItem.name }}
            </p>
          </Hovertext>
        </span>
        <slot></slot>
        <div class="menu__items">
          <menu-item
            v-for="button in actionButtons"
            @click="$emit(button.name, selectedItem)"
            :key="button.name"
            :isDanger="button.isDanger"
          >
            <unicon
              width="16px"
              height="16px"
              :name="
                button.isToggle && selectedItem[button.toggleProperty] ? button.icon2 : button.icon
              "
              fill="currentColor"
            ></unicon>
            {{
              button.isToggle && selectedItem[button.toggleProperty] ? button.name2 : button.name
            }}
          </menu-item>
        </div>
      </ul>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import MenuItem from '@/components/menu/MenuItem.vue';
import Button from '@/components/buttons/Button.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import SortableItem from '@/components/sortableList/SortableItem.vue';
import SortableList from '@/components/sortableList/SortableList.vue';
import Hovertext from '@/components/Hovertext.vue';

export default {
  name: 'Sidemenu',

  components: {
    MenuItem,
    Button,
    IconButton,
    SortableList,
    SortableItem,
    Hovertext,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: () => ({
    isOpen: false,
    selectedItem: {},
    itemsCopy: [],
    searchValue: '',
    isSortable: true,
  }),

  props: {
    items: Array,
    title: String,
    actionButtons: Array,
    actionButton: Object,
  },

  computed: {
    toggleButton() {
      return this.actionButtons.find((button) => button.toggleProperty);
    },
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      if (this.isOpen) {
        const newItems = this.getSortItems();
        if (newItems.length) this.$emit('editItems', newItems);
        this.isOpen = false;
      }
    },

    getSortItems() {
      return this.itemsCopy
        .map((item, index) => ({ ...item, index }))
        .filter(
          (itemCopy, index) => this.items.find((item) => item.id === itemCopy.id).index !== index,
        );
    },

    toggle() {
      if (this.isOpen) {
        const newItems = this.getSortItems();
        if (newItems.length) this.$emit('editItems', newItems);
      } else this.selectedItem = {};
      this.isOpen = !this.isOpen;
    },

    setCopyItems() {
      this.itemsCopy = JSON.parse(JSON.stringify(this.items));
      if (Object.keys(this.selectedItem).length) {
        this.selectedItem = this.itemsCopy.find((item) => item.id === this.selectedItem.id);
      }
    },
  },

  watch: {
    isOpen() {
      this.setCopyItems();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom__side-menu {
  position: relative;
  cursor: pointer;
  transition: color 150ms, background-color 150ms, box-shadow 150ms;
  width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
  overflow: visible;
  &:hover,
  &.isOpen {
    color: var(--font-color-700);
    background-color: var(--gray-color-200);
  }

  .title {
    display: flex;
    align-items: center;
    padding: 0.425rem 0;
    & div:nth-child(2) {
      max-width: calc(100% - 1.5rem);
    }
    p {
      padding: 0 0.425rem;
      font-weight: var(--semi-bold);
      max-width: 100%;
    }
  }

  .menu__trigger:hover {
    background-color: var(--gray-color-100);
  }

  .menu__trigger:focus {
    background-color: var(--gray-color-100);
  }

  .menu__menu {
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    width: 290px;
    margin: 0;
    padding: 0.375rem 0.45rem;
    padding-bottom: 0.425rem;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(000, 000, 000, 0.1);
    border-radius: 5px;
    z-index: 999;
    overflow: visible;
  }

  .menu-enter,
  .menu-leave-to {
    opacity: 0;
  }

  .menu-enter-active,
  .menu-leave-active {
    transition: opacity 150ms;
  }

  .menu__items {
    display: flex;
    flex-flow: column;
    padding: 0 0.425rem;
    height: fit-content;

    .button {
      justify-content: left;
    }
    ul {
      max-height: 15rem;
      overflow: auto;
    }
  }

  .menu__divider {
    margin: 0.375rem 0;
  }
}

.menu__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  p {
    font-weight: var(--regular);
    line-height: 1rem;
  }
  & > div {
    display: flex;
    gap: 4px;
  }
}
p {
  font-size: 0.825rem;
  font-weight: var(--medium);
  color: inherit;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__container {
  padding: 0.375rem 0.425rem;
  padding-bottom: 0.65rem;
}

.input {
  background-color: var(--gray-color-100);
  height: 1.875rem;
  display: flex;
  flex-direction: column;
}

input::placeholder {
  font-size: 0.825rem;
  font-weight: var(--light);
}

.field:focus ~ .label {
  color: var(--main-color-500);
}

.field {
  font-size: 0.825rem;
  max-width: 100%;
  width: 100%;
  padding: 0.375rem 0.625rem;
  color: var(--font-color-700);
  border: 1px solid var(--gray-color-400);
  border-radius: 3.5px;
  transition: border-color 300ms;
  height: 100%;
}

.field:focus {
  border-color: var(--main-color-500);
  box-shadow: var(--main-color-200) 0px 0px 5px;
}
</style>
