<template>
  <li class="list-item" :class="`list-item-${size}`">
    <span v-handle class="handle">
      <unicon name="draggabledots" fill="currentColor" height="16px" width="16px" />
    </span>

    <slot></slot>
  </li>
</template>

<script>
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { ElementMixin, HandleDirective } from 'vue-slicksort';

export default {
  mixins: [ElementMixin],

  directives: { handle: HandleDirective },

  data: () => ({
    icons: {
      handle: faGripVertical,
    },
  }),

  props: {
    size: {
      type: String,
      default: 'md',
    },
  },
};
</script>

<style lang="scss">
.list-item {
  display: flex;
  align-items: center;
  grid-gap: 0.425rem;
  gap: 0.425rem;

  &-sm {
    gap: 0;
  }
}

.list-item + .list-item {
  //margin-top: 1rem;
}

.handle {
  flex: 0 0 auto;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 1rem 0rem;
  color: var(--gray-color-700);
}

.handle:hover {
  color: var(--font-color-100);
}

.item {
  flex-grow: 1;
}
</style>
