var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category__section",style:(_vm.style)},[(_vm.chart.isDefault && _vm.chart.categoryId === 'nivellaboral')?_c('div',{staticClass:"section__cards"},[_c('Card',{attrs:{"title":"Empleados","icon":"user","value":_vm.employees.length,"colors":_vm.colors}}),_c('Card',{attrs:{"title":"Masculinos","icon":"mars","value":_vm.employees.filter(function (employee) { return employee.gender === 'Masculino'; }).length,"total":_vm.employees.length,"colors":_vm.colors}}),_c('Card',{attrs:{"title":"Femeninos","icon":"venus","value":_vm.employees.filter(function (employee) { return employee.gender === 'Femenino'; }).length,"total":_vm.employees.length,"colors":_vm.colors}})],1):(_vm.chart.isDefault && _vm.showCategoryGender)?_c('div',{staticClass:"section__cards"},[_c('Card',{attrs:{"title":"Neutros","subTitle":"Puestos","icon":"suitcase","value":_vm.chartData.datasets[0].data.filter(function (data) { return data < _vm.company.genderRatio; }).length -
        _vm.chartData.datasets[1].data.filter(function (data) { return data >= _vm.company.genderRatio; }).length,"total":_vm.chartData.datasets[0].data.length,"colors":_vm.colors}}),_c('Card',{attrs:{"title":"Masculinos","subTitle":"Puestos","icon":"suitcase","value":_vm.chartData.datasets[0].data.filter(function (data) { return data >= _vm.company.genderRatio; }).length,"total":_vm.chartData.datasets[0].data.length,"colors":_vm.colors}}),_c('Card',{attrs:{"title":"Femeninos","subTitle":"Puestos","icon":"suitcase","value":_vm.chartData.datasets[1].data.filter(function (data) { return data >= _vm.company.genderRatio; }).length,"total":_vm.chartData.datasets[1].data.length,"colors":_vm.colors}})],1):_vm._e(),_c('div',{staticClass:"section__chart",class:{
      category__haveCard:
        _vm.chart.isDefault && (_vm.chart.categoryId === 'nivellaboral' || _vm.chart.categoryId === 'puesto'),
    }},[_c('div',{staticClass:"chart__title"},[_c('h3',[_vm._v(_vm._s(_vm.displayName))]),_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16.5px","width":"16.5px"}})]},proxy:true},{key:"options",fn:function(){return [(_vm.isTableView)?_c('menu-item',{on:{"click":function($event){_vm.isTableView = false}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"chart","fill":"var(--font-color-700)"}}),_vm._v(" Ver gráfico ")],1):_c('menu-item',{on:{"click":function($event){_vm.isTableView = true}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"table","fill":"var(--font-color-700)"}}),_vm._v(" Ver tabla ")],1),_c('menu-item',{on:{"click":_vm.downloadExcel}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar Excel ")],1),(!_vm.chart.isDefault)?_c('span',{staticClass:"menu__divider"}):_vm._e(),(!_vm.chart.isDefault)?_c('menu-item',{attrs:{"isDanger":true},on:{"click":function($event){return _vm.$emit('delete', _vm.chart)}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"trash","fill":"var(--danger-color-400)"}}),_vm._v(" Eliminar ")],1):_vm._e()]},proxy:true}])})],1),(!_vm.isTableView)?_c('div',{staticClass:"chart__container"},[_c('bar-chart',{attrs:{"chart-data":_vm.chartData,"chart-options":{
          max: 1,
          steps: 5,
          stepSize: 0.2,
          indexAxis: _vm.chart.direction === 'v' ? 'x' : 'y',
          scales: ( _obj = {}, _obj[_vm.chart.direction === 'v' ? 'y' : 'x'] = {
              stacked: _vm.chart.stacked,
              display: !_vm.chart.stacked,
            }, _obj[_vm.chart.direction === 'v' ? 'x' : 'y'] = {
              stacked: _vm.chart.stacked,
              position: 'right',
            }, _obj ),
          plugins: {
            tooltip: {
              xAlign: _vm.chart.direction === 'v' ? 'center' : 'right',
              yAlign: _vm.chart.direction === 'v' ? 'bottom' : 'center',
              callbacks: {
                title: function (ref) {
                    var context = ref[0];

                    return _vm.chartData.labels[context.dataIndex].join(' ');
},
                label: function (context) {
                  return [
                    'Valor: ' +
                      Math.round(
                        context.parsed[_vm.chart.direction === 'v' ? 'y' : 'x'] *
                          _vm.chartData.dataTotals[context.dataIndex]
                      ) ];
                },
              },
            },
          },
        }}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTableView),expression:"isTableView"}],staticClass:"table__container"},[_c('table',{ref:"table"},[_c('tbody',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.categoryName))]),(_vm.chart.categoryId === 'puesto' && _vm.chart.isDefault)?_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Género "),_c('br'),_vm._v(" del puesto ")]):_vm._e(),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Masculino")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Femenino")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Total")])]),_vm._m(0),_vm._l((_vm.chartData.labels.length),function(index){return _c('tr',{key:(index + "-categoryTable")},[_c('td',[_vm._v(_vm._s(_vm.chartData.labels[index - 1][0]))]),(_vm.chart.categoryId === 'puesto' && _vm.chart.isDefault)?_c('td',[_vm._v(" "+_vm._s(_vm.chartData.labels[index - 1][1].replace(/\(|\)/g, ''))+" ")]):_vm._e(),_vm._l((2),function(genderIndex){return [_c('td',{key:(genderIndex + "-" + index + "-value")},[_vm._v(" "+_vm._s(Math.round( _vm.chartData.datasets[genderIndex - 1].data[index - 1] * _vm.chartData.dataTotals[index - 1] ))+" ")]),_c('td',{key:(genderIndex + "-" + index + "-percent")},[_vm._v(" "+_vm._s((_vm.chartData.datasets[genderIndex - 1].data[index - 1] * 100) .toFixed(0) .replace(/[.,]0$/, ''))+" ")])]}),_c('td',[_vm._v(_vm._s(_vm.chartData.dataTotals[index - 1]))])],2)})],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"second__header"},[_c('th',[_vm._v("#")]),_c('th',[_vm._v("%")]),_c('th',[_vm._v("#")]),_c('th',[_vm._v("%")])])}]

export { render, staticRenderFns }