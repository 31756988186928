<template>
  <ul class="list">
    <slot></slot>
  </ul>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort';

export default {
  mixins: [ContainerMixin],
};
</script>

<style scoped>
.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
