<template>
  <div class="category__section">
    <div class="section__chart">
      <div class="chart__title">
        <h3>{{ displayName }}</h3>

        <Menu direction="left">
          <template #label>
            <unicon
              class="ellipsis"
              name="ellipsis-h"
              fill="currentColor"
              height="16.5px"
              width="16.5px"
            />
          </template>
          <template #options>
            <menu-item v-if="isTableView" @click="isTableView = false">
              <unicon width="16px" height="16px" name="chart" fill="var(--font-color-700)"></unicon>
              Ver gráfico
            </menu-item>
            <menu-item v-else @click="isTableView = true">
              <unicon width="16px" height="16px" name="table" fill="var(--font-color-700)"></unicon>
              Ver tabla
            </menu-item>
            <menu-item @click="downloadExcel">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar Excel
            </menu-item>
          </template>
        </Menu>
      </div>
      <div v-if="!isTableView" class="chart__container">
        <bar-chart
          :chart-data="chartData"
          :chart-options="{
            max: 1,
            steps: 5,
            stepSize: 0.2,
            indexAxis: 'x',
            datasets: {
              bar: {
                barPercentage: 0.4,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                xAlign: 'center',
                yAlign: 'bottom',
                callbacks: {
                  label: (context) => {
                    return [`Valor: ${Math.round(context.parsed.y * employees.length)}`];
                  },
                  title: ([context]) => chartData.labels[context.dataIndex],
                },
              },
            },
          }"
        />
      </div>
      <div v-show="isTableView" class="table__container">
        <table ref="table">
          <tbody>
            <tr>
              <th rowspan="2">{{ categoryName }}</th>
              <th colspan="2">Masculino</th>
              <th colspan="2">Femenino</th>
              <th rowspan="2">Total</th>
            </tr>
            <tr>
              <th>#</th>
              <th>%</th>
              <th>#</th>
              <th>%</th>
            </tr>
            <tr>
              <td>{{ categoryName }}</td>
              <td>
                {{ Math.round(chartData.datasets[0].data[0] * chartData.total) }}
              </td>
              <td>
                {{ (chartData.datasets[0].data[0] * 100).toFixed(1).replace(/[.,]0$/, '') }}
              </td>
              <td>
                {{ Math.round(chartData.datasets[0].data[1] * chartData.total) }}
              </td>
              <td>
                {{ (chartData.datasets[0].data[1] * 100).toFixed(1).replace(/[.,]0$/, '') }}
              </td>
              <td>{{ chartData.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BarChart from '@/components/charts/BarChart.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import * as XLSX from 'xlsx';

export default {
  components: {
    BarChart,
    Menu,
    MenuItem,
  },
  data() {
    return { isTableView: false };
  },

  props: {
    employees: {
      required: true,
      type: Array,
    },
    colors: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getEmployeesByGender() {
      const data = [0, 0];
      this.employees.forEach((employee) => {
        const employeeGenderIndex = employee.gender === 'Masculino' ? 0 : 1;
        data[employeeGenderIndex] += 1;
      });

      return data;
    },

    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);
      XLSX.utils.book_append_sheet(wb, sheet, this.displayName);

      XLSX.writeFile(wb, 'reporte.xlsx');
    },
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
    displayName() {
      return `${this.categoryName} x Género`;
    },
    categoryName() {
      return 'Empleados';
    },
    chartData() {
      const labels = ['Masculino', 'Femenino'];
      const data = this.getEmployeesByGender();
      const total = data[0] + data[1];
      const datasets = [
        {
          backgroundColor: this.colors,
          data: [data[0] / total, data[1] / total],
        },
      ];
      return { labels, datasets, total: this.employees.length };
    },
  },
};
</script>

<style lang="scss" scoped>
.category__section {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  height: 100%;
}

.section__chart {
  border: solid 1px var(--gray-color-500);
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  .chart__title {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    padding-bottom: 1rem;
    font-size: 1.1rem;
  }

  .chart__container {
    flex-grow: 1;
    & > div {
      height: 100%;
    }
  }

  .table__container {
    margin-top: 0.3rem;
    overflow: auto;
    table {
      min-width: 100%;
    }
  }
}
</style>
