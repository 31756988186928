import { render, staticRenderFns } from "./GenderDoughnutChart.vue?vue&type=template&id=2d23b694&scoped=true&"
import script from "./GenderDoughnutChart.vue?vue&type=script&lang=js&"
export * from "./GenderDoughnutChart.vue?vue&type=script&lang=js&"
import style0 from "./GenderDoughnutChart.vue?vue&type=style&index=0&id=2d23b694&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d23b694",
  null
  
)

export default component.exports