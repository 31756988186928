<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Equidad de género', icon: 'balance-scale' }"
        />
        <h2>Equidad de género</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="openGenderRatioModal">
              <unicon
                width="16px"
                height="16px"
                name="adjust-half"
                fill="var(--font-color-700)"
              ></unicon>
              Género de puesto
            </menu-item>
            <menu-item @click="showFilters = !showFilters">
              <unicon
                width="16px"
                height="16px"
                name="filter"
                fill="var(--font-color-700)"
              ></unicon>
              Filtrar
            </menu-item>
            <menu-item @click="openExportModal">
              <unicon name="file-upload" fill="currentColor" height="16px" width="16px"></unicon>
              Exportar
            </menu-item>
          </template>
        </Menu>
        <Sidemenu
          ref="charts__menu"
          title="Gráficos"
          :actionButton="{ label: 'Nuevo gráfico', action: 'addChart' }"
          :items="
            charts.map((chart) => ({
              ...chart,
              name: `${categories.find((category) => category.id === chart.categoryId).name} x ${(
                chart.gender || 'G'
              ).slice(0, 1)} / ${chart.type}${chart.direction ? '-' : ''}${(
                chart.direction || ''
              ).toUpperCase()}`,
            }))
          "
          :actionButtons="[
            {
              icon: 'eye-slash',
              icon2: 'eye',
              name: 'Ocultar',
              name2: 'Mostrar',
              isToggle: true,
              toggleProperty: 'isHidden',
            },
            { icon: 'trash', name: 'Eliminar', isDanger: true },
          ]"
          @addChart="openChartModal"
          @Eliminar="onDeleteChart"
          @Ocultar="toggleHideChart"
          @editItems="editCharts"
        >
          <template #label>
            <Button type="button" variant="secondary" size="small"> Gráficos </Button>
          </template>
        </Sidemenu>
      </div>
    </div>

    <div v-show="showFilters" class="filter__container">
      <Filters
        @filter="
          (activeFilters) => {
            filterEmployees(activeFilters);
          }
        "
        :filters="[{ name: 'Fecha', id: 'birthDate', type: 'month' }, ...categories]"
        :filtersOptions="options"
        :mountFilters="
          selectedDate
            ? [
                {
                  name: 'Fecha',
                  id: 'birthDate',
                  type: 'month',
                  options: [
                    {
                      active: true,
                      id: `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}`,
                    },
                  ],
                },
              ]
            : []
        "
        ref="filters"
      />
    </div>

    <div class="content">
      <div v-if="!isLoading" class="dashboard">
        <GenderBarChart
          :employees="filteredEmployees"
          :chart="{
            categoryId: 'nivellaboral',
            type: 'Barras',
            direction: 'h',
            stacked: true,
            isDefault: true,
          }"
          :colors="company.colors"
          ref="chart-0"
        />
        <EmployeeBarChart :employees="filteredEmployees" :colors="company.colors" ref="chart-1" />
        <GenderDoughnutChart
          :chart="{ categoryId: 'liderazgo', type: 'Dónut', isDefault: true }"
          :employees="
            filteredEmployees.filter(
              (employee) =>
                employee.liderazgo ===
                options.find((option) => option.categoryId === 'liderazgo' && option.name === 'Si')
                  .id,
            )
          "
          :colors="company.colors"
          ref="chart-2"
        />
        <GenderDoughnutChart
          :chart="{ categoryId: 'estrategico', type: 'Dónut', isDefault: true }"
          :employees="
            filteredEmployees.filter(
              (employee) =>
                employee.estrategico ===
                options.find(
                  (option) => option.categoryId === 'estrategico' && option.name === 'Si',
                ).id,
            )
          "
          :colors="company.colors"
          ref="chart-3"
        />
        <GenderBarChart
          :employees="filteredEmployees"
          :chart="{
            categoryId: 'puesto',
            type: 'Barras',
            direction: 'h',
            stacked: true,
            isDefault: true,
          }"
          :showCategoryGender="true"
          :colors="company.colors"
          ref="chart-4"
        />
        <GenderBarChart
          :employees="filteredEmployees"
          :chart="{
            categoryId: 'gerencia',
            type: 'Barras',
            direction: 'h',
            stacked: true,
            isDefault: true,
          }"
          :colors="company.colors"
          ref="chart-5"
        />
        <GenderBarChart
          :employees="filteredEmployees"
          :chart="{
            categoryId: 'area',
            type: 'Barras',
            direction: 'h',
            stacked: true,
            isDefault: true,
          }"
          :colors="company.colors"
          ref="chart-6"
        />
        <div v-if="charts.length">
          <template v-for="(chart, index) in charts.filter((chart) => !chart.isHidden)">
            <GenderBarChart
              :key="chart.id"
              v-if="chart.type === 'Barras'"
              :employees="filteredEmployees"
              :chart="chart"
              :colors="company.colors"
              @delete="onDeleteChart"
              :ref="`chart-${index + 7}`"
            />
            <CategoryDoughnutChart
              :key="chart.id"
              v-else
              :employees="filteredEmployees.filter((employee) => employee.gender === chart.gender)"
              :chart="chart"
              :colors="company.colors"
              @delete="onDeleteChart"
              :ref="`chart-${index + 7}`"
            />
          </template>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="loading"><loading-spinner /></div>

    <gender-ratio-modal ref="modal__genderRatio" />
    <chart-modal ref="modal__chart" />
    <export-modal
      ref="modal__export"
      @export="onExport($event)"
      :formats="{
        ppt: {
          id: 'ppt',
          name: 'PPT',
          options: [{ id: 'gender', name: 'Equidad de género' }],
        },
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [{ id: 'gender', name: 'Equidad de género' }],
        },
      }"
    />
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faFilePowerpoint, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Button from '@/components/buttons/Button.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Sidemenu from '@/components/menu/Sidemenu.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Filters from '@/components/filters/Filters.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import ExportModal from '@/components/ExportModal.vue';
import { timeStampToDMY, YMDToTimeStamp } from '@/dateFormats';
import pptxgen from 'pptxgenjs';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';
import EmployeeBarChart from './EmployeeBarChart.vue';
import GenderBarChart from './GenderBarChart.vue';
import GenderDoughnutChart from './GenderDoughnutChart.vue';
import CategoryDoughnutChart from './CategoryDoughnutChart.vue';
import GenderRatioModal from './modals/GenderRatioModal.vue';
import ChartModal from './modals/ChartModal.vue';

export default {
  components: {
    Button,
    Breadcrumbs,
    Sidemenu,
    EmployeeBarChart,
    GenderBarChart,
    ChartModal,
    GenderRatioModal,
    ExportModal,
    GenderDoughnutChart,
    CategoryDoughnutChart,
    ConfirmDialogue,
    Filters,
    LoadingSpinner,
    Menu,
    MenuItem,
  },
  data() {
    return {
      isLoading: true,
      icons: { ppt: faFilePowerpoint, excel: faFileExcel },
      positiondData: {},
      filteredEmployees: [],
      showFilters: false,
      timeStampToDMY,
      YMDToTimeStamp,
    };
  },

  async mounted() {
    if (this.selectedDate) this.showFilters = true;
    try {
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      if (!this.charts.length) await this.fetchCharts();
    } catch (error) {
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
    } finally {
      this.isLoading = false;
    }
    this.filterEmployees();
  },

  methods: {
    ...mapActions(['updateCompany']),
    ...mapActions('employees', ['fetchEmployees', 'batchDelete']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('charts', ['fetchCharts', 'deleteChart', 'updateChart', 'updateChartsBatch']),
    ...mapActions('options', ['fetchOptions']),
    ...mapMutations(['setAlert']),

    onExport(exportConfig) {
      if (exportConfig.format === 'ppt') this.downloadPPT();
      if (exportConfig.format === 'excel') this.downloadExcel();
    },

    openExportModal() {
      this.$refs.modal__export.open();
    },

    isActive({ entryDate, terminationDate }, entryDateFilter) {
      const currentDate = this.YMDToTimeStamp(`${entryDateFilter}-30`).seconds;
      if (!terminationDate || !terminationDate.seconds) return currentDate > entryDate.seconds;
      return currentDate > entryDate.seconds && terminationDate.seconds > currentDate;
    },

    filterEmployees(filters = []) {
      const activeFilters = [];
      let employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (filter.type === 'month') {
          if (filter.options[0].id) {
            employees = employees.filter((emp) => this.isActive(emp, filter.options[0].id));
          }
        } else if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            options: activeOptions,
          });
        }
      });
      this.filteredEmployees = employees.filter((employee) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          if (!filter.options.includes(employee[filter.id])) {
            return false;
          }
        }
        return true;
      });
    },

    openChartModal() {
      this.$refs.modal__chart.open();
    },

    openGenderRatioModal() {
      this.$refs.modal__genderRatio.open();
    },

    async onDeleteChart(chart) {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar gráfico',
        message:
          '¿Estas seguro que quieres eliminar este gráfico? Eliminar un gráfico lo removera permanentemente, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      try {
        await this.deleteChart(chart.id);

        this.setAlert({
          state: 'success',
          message: 'Gráfico eliminada',
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.$refs.charts__menu.selectedItem = {};
      }
    },
    async toggleHideChart(selectedChart) {
      const chart = this.charts.find((e) => e.id === selectedChart.id);
      await this.updateChart({ ...selectedChart, isHidden: !chart.isHidden });
      this.$refs.charts__menu.setCopyItems();
    },
    async editCharts(chartsToEdit) {
      await this.updateChartsBatch(chartsToEdit);
    },

    downloadPPT() {
      const chartsData = [];
      const chartsNames = [];
      const charts = [];
      for (let index = 0; index < this.charts.length + 7; index += 1) {
        chartsData.push(
          (index < 7 ? this.$refs[`chart-${index}`] : this.$refs[`chart-${index}`][0]).chartData,
        );
        chartsNames.push(
          (index < 7 ? this.$refs[`chart-${index}`] : this.$refs[`chart-${index}`][0]).categoryName,
        );
        charts.push(
          (index < 7 ? this.$refs[`chart-${index}`] : this.$refs[`chart-${index}`][0]).chart,
        );
      }
      /* eslint-disable */
      const pptx = new pptxgen();
      const config = {
        x: 1,
        // y: 1.2,
        y: 0.31,
        w: 8,
        // h: 4.3,
        h: 5,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: this.company.colors.map((color) => color.slice(1)),
        dataLabelPosition: 't',
        dataLabelFormatCode: '#%',
        valAxisLabelFormatCode: '#%',
        showValue: true,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
        valAxisMaxVal: 1,
      };

      const slide = pptx.addSlide();

      slide.addText('Equidad de género', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      this.$refs.filters.activeFilters.forEach((filter, i) => {
        let options = filter.options.filter((option) => option.active);
        text += options.length ? ' / ' + filter.name + ': ' : '';
        options.forEach((option, index) => {
          text += option.name + (index == options.length - 1 ? '' : ', ');
        });
        text += text.length && i === this.$refs.filters.activeFilters.length - 1 ? ' /' : '';
      });
      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });

      chartsData.forEach((chart, index) => {
        const slide = pptx.addSlide();
        const chartData = [];
        chart.datasets.forEach((data, i) => {
          chartData.push({
            name: i === 0 ? 'Masculino' : 'Femenino',
            labels: chart.labels.map((val) => (Array.isArray(val) ? val.join(' ') : val)),
            values: data.data.map((data) => parseFloat(data.toFixed(2))),
          });
        });
        !charts[index] ? (charts[index] = { type: 'Barras' }) : '';
        slide.addChart(
          charts[index].type === 'Barras' ? pptx.ChartType.bar : pptx.ChartType.doughnut,
          chartData,
          {
            ...config,
            title: `${chartsNames[index]} x Género`,
            barDir: charts[index].direction === 'h' ? 'bar' : 'col',
            barGrouping: charts[index].stacked ? 'stacked' : '',
          },
        );
      });

      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    downloadExcel() {
      const wb = {
        SheetNames: [],
        Sheets: {},
      };
      const usedSheetNames = {};
      for (let index = 0; index < this.charts.length + 7; index += 1) {
        const $chart = index < 7 ? this.$refs[`chart-${index}`] : this.$refs[`chart-${index}`][0];
        const sheet = XLSX.utils.table_to_sheet($chart.$refs.table);
        const label = $chart.displayName;
        usedSheetNames[label] = (usedSheetNames[label] || 0) + 1;
        const sheetName = `${label}${usedSheetNames[label] > 1 ? usedSheetNames[label] : ''}`;
        wb.Sheets[sheetName] = sheet;
        wb.SheetNames.push(sheetName);
      }

      wb.SheetNames.forEach((sheetName) => {
        const ws = wb.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(wb, {
        bookType: 'xlsx',
        type: 'binary',
      });
      this.saveBlobAsFile(
        'reporte.xlsx',
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },

    getReportData() {
      let chartsData = [];
      for (let index = 0; index < this.charts.length + 7; index += 1) {
        const $chart = index < 7 ? this.$refs[`chart-${index}`] : this.$refs[`chart-${index}`][0];
        chartsData.push({
          ...$chart.chartData,
          datasets: $chart.chartData.datasets.map(({ data }) => data),
          name: $chart.categoryName,
          gender: $chart.chart ? $chart.chart.gender : '',
        });
      }
      return chartsData;
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({
        ...state.company,
        colors: state.company.colors,
      }),
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
      charts: (state) => state.charts.charts,
      selectedDate: (state) => state.selectedDate,
    }),
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

.content {
  width: 100%;
  background-color: white;
  flex-grow: 1;
  overflow: auto;
}

.dashboard {
  gap: 1rem;
  display: grid;
  grid-template-areas:
    'category estrategy leadership'
    'category estrategy leadership'
    'category employees employees'
    'category employees employees'
    'position management management'
    'position management management'
    'position area area'
    'position area area'
    'rest rest rest';
  grid-template-rows: repeat(2, 7rem) repeat(2, 8rem) repeat(4, 7.5rem) auto;
  grid-template-columns: calc(50% - 0.75rem) repeat(2, calc(25% - 0.75rem));

  & > * {
    &:first-child {
      grid-area: category;
    }
    &:nth-child(2) {
      grid-area: employees;
    }
    &:nth-child(3) {
      grid-area: estrategy;
    }
    &:nth-child(4) {
      grid-area: leadership;
    }
    &:nth-child(5) {
      grid-area: position;
    }
    &:nth-child(6) {
      grid-area: management;
    }
    &:nth-child(7) {
      grid-area: area;
    }
    &:nth-child(8) {
      grid-area: rest;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 0.5rem));
      gap: 1rem;
      & > * {
        // min-width: 36rem;
        height: 26rem;
      }
    }
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
</style>
