<template>
  <div class="category__section">
    <div class="section__chart">
      <div class="chart__title">
        <h3>{{ displayName }}</h3>

        <Menu direction="left">
          <template #label>
            <unicon
              class="ellipsis"
              name="ellipsis-h"
              fill="currentColor"
              height="16.5px"
              width="16.5px"
            />
          </template>
          <template #options>
            <menu-item v-if="isTableView" @click="isTableView = false">
              <unicon width="16px" height="16px" name="chart" fill="var(--font-color-700)"></unicon>
              Ver gráfico
            </menu-item>
            <menu-item v-else @click="isTableView = true">
              <unicon width="16px" height="16px" name="table" fill="var(--font-color-700)"></unicon>
              Ver tabla
            </menu-item>
            <menu-item @click="downloadExcel">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar Excel
            </menu-item>
            <span v-if="!chart.isDefault" class="menu__divider"></span>
            <menu-item v-if="!chart.isDefault" @click="$emit('delete', chart)" :isDanger="true">
              <unicon
                width="16px"
                height="16px"
                name="trash"
                fill="var(--danger-color-400)"
              ></unicon>
              Eliminar
            </menu-item>
          </template>
        </Menu>
      </div>
      <div v-if="!isTableView" class="chart__container">
        <doughnut-chart
          :chart-data="chartData"
          :chart-options="{
            cutout: 0,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return [
                      `Valor: ${Math.round(
                        chartData.datasets[0].data[context.dataIndex] * employees.length,
                      )}`,
                    ];
                  },
                  title: ([context]) => chartData.labels[context.dataIndex],
                },
              },
            },
          }"
        />
      </div>

      <div v-show="isTableView" class="table__container">
        <table ref="table">
          <tbody>
            <tr>
              <th rowspan="2">{{ categoryName }}</th>
              <th colspan="2">{{ chart.gender }}</th>
            </tr>
            <tr class="second__header">
              <th>#</th>
              <th>%</th>
            </tr>
            <tr v-for="index in chartData.labels.length" :key="`${index}-categoryTable`">
              <td>{{ chartData.labels[index - 1] }}</td>
              <template v-for="genderIndex in 1">
                <td :key="`${genderIndex}-${index}-value`">
                  {{
                    Math.round(
                      chartData.datasets[genderIndex - 1].data[index - 1] * chartData.total,
                    )
                  }}
                </td>
                <td :key="`${genderIndex}-${index}-percent`">
                  {{
                    (chartData.datasets[genderIndex - 1].data[index - 1] * 100)
                      .toFixed(1)
                      .replace(/[.,]0$/, '')
                  }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import * as XLSX from 'xlsx';

export default {
  components: {
    DoughnutChart,
    Menu,
    MenuItem,
  },
  data() {
    return { isTableView: false };
  },

  props: {
    employees: {
      required: true,
      type: Array,
    },
    chart: {
      required: true,
      type: Object,
    },
    colors: {
      type: Array,
      required: true,
    },
  },

  methods: {
    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);
      XLSX.utils.book_append_sheet(wb, sheet, `${this.categoryName} x Género`);

      XLSX.writeFile(wb, 'reporte.xlsx');
    },

    getEmptyIndex(arr) {
      /* eslint-disable */
      return arr.reduce((acc, v, i) => (v === 0 && acc.push(i), acc), []);
    },

    filterEmptyValues(arr, emptyIndexes) {
      return arr.filter((_, index) => emptyIndexes.indexOf(index) === -1);
    },

    getEmployeesByCategoryOption(categoryId, categoryOptions) {
      let total = 0;
      let data = categoryOptions.map(() => 0);
      this.employees.forEach((employee) => {
        const employeeOption = categoryOptions.find((option) => option.id === employee[categoryId]);
        if (employeeOption) {
          data[employeeOption.index] += 1;
          total += 1;
        }
      });
      return {
        total,
        data,
      };
    },
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    displayName() {
      return `${this.categoryName} x ${this.chart.gender}`;
    },

    categoryName() {
      return this.categories.find((category) => category.id === this.chart.categoryId).name;
    },

    chartData() {
      const { categoryId } = this.chart;
      let categoryOptions = this.options
        .filter((option) => option.categoryId === categoryId)
        .map((option, index) => ({ ...option, index }));
      const labels = categoryOptions.map((option) => option.name);
      const { total, data } = this.getEmployeesByCategoryOption(categoryId, categoryOptions);

      const emptyIndexes = this.getEmptyIndex(data);

      return {
        labels: this.filterEmptyValues(labels, emptyIndexes),
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.filterEmptyValues(
              data.map((val) => val / total || 0),
              emptyIndexes,
            ),
          },
        ],
        total: this.employees.length,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.category__section {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  height: 100%;
}

.section__chart {
  border: solid 1px var(--gray-color-500);
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  height: 100%;
  .chart__title {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    padding-bottom: 1rem;
    font-size: 1.1rem;
  }
  .chart__container {
    flex-grow: 1;
    & > div {
      height: 100%;
    }
  }

  .table__container {
    margin-top: 0.3rem;
    overflow: auto;
    table {
      min-width: 100%;
    }
  }
}
</style>
