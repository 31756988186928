<template>
  <button
    :disabled="disabled"
    :title="label"
    class="icon-button"
    type="button"
    @click="$emit('click', $event)"
  >
    <unicon
      :name="icon"
      :fill=" danger ? 'var(--danger-color-500)': '#8D929D'"
      height="19px"
      width="19px"
    />
  </button>
</template>

<script>
export default {
  name: 'IconButton',

  props: {
    danger: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    icon: {
      required: true,
      type: String,
    },

    label: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  color: var(--font-color-500);
  font-size: 1rem;
  border-radius: 50%;
  transition: color 150ms, background-color 150ms, box-shadow 150ms;

  &:disabled {
    color: var(--font-color-100);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: var(--font-color-700);
    background-color: var(--gray-color-200);
  }

  &:active:not(:disabled) {
    background-color: var(--gray-color-300);
  }

  &:focus {
    box-shadow: 0 0 0 4px var(--main-color-100);
  }
}
</style>
