<template>
  <div class="tooltip__container">
    <slot></slot>
    <div class="tip">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hovertext',
  data: () => ({}),

  props: {
    text: {
      default: '',
      type: String,
    },
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.tooltip__container {
  max-width: 100%;
  position: relative;
  cursor: help;
  &:hover {
    .tip {
      display: block;
    }
  }
  .tip {
    display: none;
    background-color: var(--font-color-500);
    border-radius: 5px;
    text-align: center;
    position: absolute;
    bottom: 100%;
    left: 10%;
    padding: 0.4rem 0.5rem;
    p {
      font-size: 0.7rem;
      color: white;
      line-height: 1rem;
    }
  }
}
</style>
