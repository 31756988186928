var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[],"currentView":{ label: 'Equidad de género', icon: 'balance-scale' }}}),_c('h2',[_vm._v("Equidad de género")])],1),_c('div',{staticClass:"content__buttons"},[_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.openGenderRatioModal}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"adjust-half","fill":"var(--font-color-700)"}}),_vm._v(" Género de puesto ")],1),_c('menu-item',{on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"filter","fill":"var(--font-color-700)"}}),_vm._v(" Filtrar ")],1),_c('menu-item',{on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"name":"file-upload","fill":"currentColor","height":"16px","width":"16px"}}),_vm._v(" Exportar ")],1)]},proxy:true}])}),_c('Sidemenu',{ref:"charts__menu",attrs:{"title":"Gráficos","actionButton":{ label: 'Nuevo gráfico', action: 'addChart' },"items":_vm.charts.map(function (chart) { return (Object.assign({}, chart,
            {name: ((_vm.categories.find(function (category) { return category.id === chart.categoryId; }).name) + " x " + ((
              chart.gender || 'G'
            ).slice(0, 1)) + " / " + (chart.type) + (chart.direction ? '-' : '') + ((
              chart.direction || ''
            ).toUpperCase()))})); }),"actionButtons":[
          {
            icon: 'eye-slash',
            icon2: 'eye',
            name: 'Ocultar',
            name2: 'Mostrar',
            isToggle: true,
            toggleProperty: 'isHidden',
          },
          { icon: 'trash', name: 'Eliminar', isDanger: true } ]},on:{"addChart":_vm.openChartModal,"Eliminar":_vm.onDeleteChart,"Ocultar":_vm.toggleHideChart,"editItems":_vm.editCharts},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"small"}},[_vm._v(" Gráficos ")])]},proxy:true}])})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"filter__container"},[_c('Filters',{ref:"filters",attrs:{"filters":[{ name: 'Fecha', id: 'birthDate', type: 'month' } ].concat( _vm.categories),"filtersOptions":_vm.options,"mountFilters":_vm.selectedDate
          ? [
              {
                name: 'Fecha',
                id: 'birthDate',
                type: 'month',
                options: [
                  {
                    active: true,
                    id: ((_vm.selectedDate.getFullYear()) + "-" + ((_vm.selectedDate.getMonth() + 1)
                      .toString()
                      .padStart(2, '0'))),
                  } ],
              } ]
          : []},on:{"filter":function (activeFilters) {
          _vm.filterEmployees(activeFilters);
        }}})],1),_c('div',{staticClass:"content"},[(!_vm.isLoading)?_c('div',{staticClass:"dashboard"},[_c('GenderBarChart',{ref:"chart-0",attrs:{"employees":_vm.filteredEmployees,"chart":{
          categoryId: 'nivellaboral',
          type: 'Barras',
          direction: 'h',
          stacked: true,
          isDefault: true,
        },"colors":_vm.company.colors}}),_c('EmployeeBarChart',{ref:"chart-1",attrs:{"employees":_vm.filteredEmployees,"colors":_vm.company.colors}}),_c('GenderDoughnutChart',{ref:"chart-2",attrs:{"chart":{ categoryId: 'liderazgo', type: 'Dónut', isDefault: true },"employees":_vm.filteredEmployees.filter(
            function (employee) { return employee.liderazgo ===
              _vm.options.find(function (option) { return option.categoryId === 'liderazgo' && option.name === 'Si'; })
                .id; }
          ),"colors":_vm.company.colors}}),_c('GenderDoughnutChart',{ref:"chart-3",attrs:{"chart":{ categoryId: 'estrategico', type: 'Dónut', isDefault: true },"employees":_vm.filteredEmployees.filter(
            function (employee) { return employee.estrategico ===
              _vm.options.find(
                function (option) { return option.categoryId === 'estrategico' && option.name === 'Si'; }
              ).id; }
          ),"colors":_vm.company.colors}}),_c('GenderBarChart',{ref:"chart-4",attrs:{"employees":_vm.filteredEmployees,"chart":{
          categoryId: 'puesto',
          type: 'Barras',
          direction: 'h',
          stacked: true,
          isDefault: true,
        },"showCategoryGender":true,"colors":_vm.company.colors}}),_c('GenderBarChart',{ref:"chart-5",attrs:{"employees":_vm.filteredEmployees,"chart":{
          categoryId: 'gerencia',
          type: 'Barras',
          direction: 'h',
          stacked: true,
          isDefault: true,
        },"colors":_vm.company.colors}}),_c('GenderBarChart',{ref:"chart-6",attrs:{"employees":_vm.filteredEmployees,"chart":{
          categoryId: 'area',
          type: 'Barras',
          direction: 'h',
          stacked: true,
          isDefault: true,
        },"colors":_vm.company.colors}}),(_vm.charts.length)?_c('div',[_vm._l((_vm.charts.filter(function (chart) { return !chart.isHidden; })),function(chart,index){return [(chart.type === 'Barras')?_c('GenderBarChart',{key:chart.id,ref:("chart-" + (index + 7)),refInFor:true,attrs:{"employees":_vm.filteredEmployees,"chart":chart,"colors":_vm.company.colors},on:{"delete":_vm.onDeleteChart}}):_c('CategoryDoughnutChart',{key:chart.id,ref:("chart-" + (index + 7)),refInFor:true,attrs:{"employees":_vm.filteredEmployees.filter(function (employee) { return employee.gender === chart.gender; }),"chart":chart,"colors":_vm.company.colors},on:{"delete":_vm.onDeleteChart}})]})],2):_vm._e()],1):_vm._e()]),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e(),_c('gender-ratio-modal',{ref:"modal__genderRatio"}),_c('chart-modal',{ref:"modal__chart"}),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      ppt: {
        id: 'ppt',
        name: 'PPT',
        options: [{ id: 'gender', name: 'Equidad de género' }],
      },
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [{ id: 'gender', name: 'Equidad de género' }],
      },
    }},on:{"export":function($event){return _vm.onExport($event)}}}),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }